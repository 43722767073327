<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select
            v-model="dataForm.orgId"
            :disabled="disabled || dataForm.state === 1"
            @change="getPeriodList()"
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="公司" prop="companyId">
          <el-select
            v-model="dataForm.companyId"
            :disabled="disabled || dataForm.state === 1"
            @change="getPeriodList()"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="星期" prop="weeks" class="fullList">
          <el-checkbox-group
            :disabled="disabled || dataForm.state === 1"
            v-model="dataForm.weeks"
            class="checkBtnColor"
            size="medium"
          >
            <el-checkbox-button
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.id"
              >{{ item.week }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="时段" prop="orgPeriodId">
          <el-select
            v-model="dataForm.orgPeriodId"
            :disabled="disabled || dataForm.state === 1"
            filterable
            clearable
            multiple
            @change="getPeriodChange"
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.periodWithTimeName"
              :value="item.orgPeriodId"
            />
          </el-select>
        </el-form-item>

        <div class="holdTable">
          <el-table :data="periodTable" border stripe>
            <el-table-column
              prop="periodWithTimeName"
              header-align="center"
              align="center"
              width="150"
              label="时段名称"
            />
            <el-table-column
              header-align="center"
              align="center"
              label="是否统一"
            >
              <template slot-scope="scope">
                <div class="tableLine">
                  <div class="leftLine">
                    <el-checkbox
                      v-model="scope.row.isStartUnified"
                      :disabled="disabled"
                      size="mini"
                      >自由预售开始时间</el-checkbox
                    >
                  </div>
                  <div class="rightLine" v-show="!scope.row.isStartUnified">
                    <el-time-picker
                      :disabled="disabled"
                      v-model="scope.row.advanceStartTime"
                      value-format="HH:mm"
                      format="HH:mm"
                      class="longTime"
                      placeholder="自由预售开始时间"
                    />
                  </div>
                </div>
                <div class="tableLine">
                  <div class="leftLine">
                    <el-checkbox
                      v-model="scope.row.isPeriodUnified"
                      :disabled="disabled"
                      size="mini"
                      >时段开始时间</el-checkbox
                    >
                  </div>
                  <div class="rightLine" v-show="!scope.row.isPeriodUnified">
                    <el-time-picker
                      :disabled="disabled"
                      v-model="scope.row.startTime"
                      value-format="HH:mm"
                      format="HH:mm"
                      class="halfTime"
                      placeholder="时段开始时间"
                    />
                    <el-time-picker
                      :disabled="disabled"
                      v-model="scope.row.endTime"
                      value-format="HH:mm"
                      format="HH:mm"
                      class="halfTime"
                      placeholder="时段结束时间"
                    />
                  </div>
                </div>
                <div class="tableLine">
                  <div class="leftLine">
                    <el-checkbox
                      v-model="scope.row.isUnified"
                      :disabled="disabled"
                      size="mini"
                      >自由预售截止时间</el-checkbox
                    >
                  </div>
                  <div class="rightLine" v-show="!scope.row.isUnified">
                    <div class="leftname">
                      {{ (scope.row.cutOffTimeAny &lt; scope.row.startTime) ? "当天": "前一天"}}
                    </div>
                    <el-time-picker
                      :disabled="disabled"
                      v-model="scope.row.cutOffTimeAny"
                      value-format="HH:mm"
                      format="HH:mm"
                      class="halfTime"
                      placeholder="自由预售截单时间"
                    />
                  </div>
                </div>
                <div class="tableLine">
                  <div class="leftLine">
                    <el-checkbox
                      v-model="scope.row.isReservationUnified"
                      :disabled="disabled"
                      size="mini"
                      >限定预售截止时间</el-checkbox
                    >
                  </div>
                  <div
                    class="rightLine"
                    v-show="!scope.row.isReservationUnified"
                  >
                    <div class="leftname">
                      {{ (scope.row.cutOffTimeAny &gt; scope.row.startTime) &&
                        (scope.row.reservationTimeAny &gt; scope.row.cutOffTimeAny) ? "前一天" : "当天" }}
                    </div>
                    <el-time-picker
                      :disabled="disabled"
                      v-model="scope.row.reservationTimeAny"
                      value-format="HH:mm"
                      format="HH:mm"
                      class="halfTime"
                      placeholder="限定预售截单时间"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!disabled"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getOrgList, getCompanyList } from '@/utils/options.js';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      time: [],
      dataForm: {
        id: '',
        orgId: '',
        companyId: '',
        // cutOffTimeAny: '',
        // reservationTimeAny: '',
        orgPeriodId: [],
        startDate: '',
        endDate: '',
        weeks: [1, 2, 3, 4, 5, 6, 7],
        // isStartUnified: true,
        // advanceStartTime: '',
        // isPeriodUnified: true,
        // startTime: '',
        // endTime: '',
        // isUnified: true,
        // isReservationUnified: true,
        periodWithTimeName: '',
        state: 0,
      },
      orgList: [],
      companyList: [],
      periodList: [],
      orgPeriodCompany: [],
      dataListSelections: [],
      periodTable: [], // 时段的table
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      dataRule: {
        orgId: [{ required: true, message: '供应商不能为空', trigger: 'blur' }],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        orgPeriodId: [
          { required: true, message: '时段不能为空', trigger: 'blur' },
        ],
        startDate: [
          { required: true, message: '生效日期不能为空', trigger: 'blur' },
        ],
        endDate: [
          { required: true, message: '终止日期不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getOrgList();
    this.getCompanyList();
    this.getPeriodList();
  },
  methods: {
    init(id, disabled) {
      this.dataForm.orgPeriodId = [];
      this.periodList = [];
      this.periodTable = [];
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.dataForm.state = 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgPeriodCompany/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.orgPeriodCompany };
              this.dataForm.weeks = data.orgPeriodCompany.weeks
                ? data.orgPeriodCompany.weeks
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
              this.periodTable = data.orgPeriodCompany.periodTable;
              this.dataForm.orgPeriodId = [data.orgPeriodCompany.orgPeriodId];
              this.getPeriodList();
            }
          });
        }
      });
    },
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    //
    selectionChange(val) {
      this.dataListSelections = val;
    },
    getPeriodList() {
      if (this.dataForm.orgId && this.dataForm.companyId) {
        this.$http({
          url: `/tc/orgCompanyContract/combo`,
          method: 'get',
          params: {
            orgId: this.dataForm.orgId,
            companyId: this.dataForm.companyId,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
          }
        });
      }
    },
    getPeriodChange(data) {
      if (data && data.length > 0) {
        // 有数据时，进行判断
        this.periodTable = [];
        this.periodList.forEach((i) => {
          data.forEach((s) => {
            if (i.orgPeriodId === s) {
              this.periodTable.push({
                orgPeriodId: i.orgPeriodId,
                orgCompanyContractId: i.orgCompanyContractId,
                endTime: i.endTime,
                periodId: i.periodId,
                periodWithTimeName: i.periodWithTimeName,
                startTime: i.startTime,
                isStartUnified: true,
                advanceStartTime: i.advanceStartTime, // 自由预售开始时间
                isPeriodUnified: true,
                // periodStartTime: null, // 时段开始时间
                // periodEndTime: null, // 时段结束时间
                isUnified: true,
                state: i.state,
                cutOffTimeAny: i.cutOffTimeAny,
                isReservationUnified: true,
                reservationTimeAny: i.reservationTimeAny,
                startDate: i.startDate,
                endDate: i.endDate,
              });
            }
          });
        });
      } else {
        this.periodTable = [];
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orgPeriodCompany/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              orgId: this.dataForm.orgId,
              companyId: this.dataForm.companyId,
              startDate: this.dataForm.startDate,
              endDate: this.dataForm.endDate,
              weeks: this.dataForm.weeks.map((i) => i).join(','),
              orgPeriodIds: this.dataForm.orgPeriodId.map((i) => i).join(','),
              periodTable: this.periodTable,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.code === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.inputNumberInside {
  width: 100%;
  .el-input__inner {
    border: none;
  }
}
.tableLine {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  .leftLine {
    font-size: 12px;
    width: 160px;
    padding: 0 10px 0 0;
    text-align: left;
  }
  .rightLine {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .longTime {
      width: 100%;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
    .leftname {
      width: 90px;
      text-align: center;
      font-size: 12px;
      color: #000;
      line-height: 30px;
    }
    .halfTime {
      width: auto;
      flex: 1;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
  }
}

.newTablePart {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  .newTable {
    width: 70%;
    position: relative;
  }
  .firstTable {
    width: 30%;
    position: relative;
  }
}

.checkBtnColor {
  .is-disabled.is-checked {
    .el-checkbox-button__inner {
      background-color: #999;
      color: #fff;
    }
  }
}
</style>
